import axios from 'axios'
import jwt_decode from 'jwt-decode'

const API_URL = 'https://development.space-meets.com/api/'

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.status) {
          const user = response.data.message.username;
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('token', JSON.stringify(response.data.accessToken))
        }
        return response
      })
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  }

  isUserAuthenticated() {
    let token = JSON.parse(localStorage.getItem('token'))
    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

}

export default new AuthService()
