import Vue from 'vue'
import Router from 'vue-router'
import AuthService from '../services/auth/auth.service'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')


//Customization start from here
const ViewUser = () => import('@/views/pages/ViewUser')
const ViewAllMeeting = () => import('@/views/pages/ViewAllMeeting')
const ViewAllActivities = () => import('@/views/pages/ViewAllActivities')
const AddUser = () => import('@/views/pages/AddUser')
const EditUser = () => import('@/views/pages/EditUser')
const ViewSingleUser = () => import('@/views/pages/ViewSingleUser')



//Tickets related routes
const ViewAllTickets = () => import('@/views/pages/ViewAllTickets')
const  ViewTicketDetails = ()=> import('@/views/pages/tickets/ViewTicket')


//Meeting related routes
const  ViewMeetingDetails = ()=> import('@/views/pages/meetings/ViewMeeting')


Vue.use(Router)


const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !AuthService.isUserAuthenticated()) {
    next({
      name: 'Login',
    });
  }
  else {
    next();
  }
})



// router.beforeEach((to, from, next) => {

//   console.log(this.$store)
//   if (this.$store) {
//     next('/login');
//   }

//   next();
// });

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },

        {
          path: 'users',
          redirect: '/users/view/list',
          name: 'Users',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'view',
              name: 'View users',
              redirect: '/users/view/list',
              component: {
                render(c) { return c('router-view') }
              },
              children: [{
                path: 'list',
                name: 'List',
                component: ViewUser,
              },

              {
                path: ':id',
                name: 'User details',
                component: ViewSingleUser
              },
              ]
            },
            {
              path: 'add',
              name: 'Add User',
              component: AddUser
            },
            {
              path: 'edit',
              name: 'editUser',
              component: EditUser
            },
          ]
        },
        {
          path: 'meeting',
          redirect: '/meeting/view/list',
          name: 'Meeting',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'view',
              name: 'View all',
              redirect: '/meeting/view/list',
              component: {
                render(c) { return c('router-view') }
              },
              children: [{
                path: 'list',
                name: 'List Meeting',
                component: ViewAllMeeting,
              },
              //Rasha
              {
                path: ':id', 
                name: 'MeetingDetails', 
                component: ViewMeetingDetails
              },
              ]
            },
          ]
        },
        {
          path: 'ticket',
          redirect: '/ticket/view/list',
          name: 'Ticket',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'view',
              name: 'ViewAllTickets',
              redirect: '/ticket/view/list',
              component: {
                render(c) { return c('router-view') }
              },
              children: [{
                path: 'list',
                name: 'ListTickets',
                component: ViewAllTickets,
              },
              {
                path: ':id',
                name: 'TicketDetails',
                component: ViewTicketDetails,
              },
              ]
            },
          ]
        },
        {
          path: 'activities',
          redirect: '/activities/view/list',
          name: 'Activities',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'view',
              name: 'View activities',
              redirect: '/activities/view/list',
              component: {
                render(c) { return c('router-view') }
              },
              children: [{
                path: 'list',
                name: 'List activities',
                component: ViewAllActivities,
              },
              ]
            },
          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },

        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    },

  ]
}

export default router;