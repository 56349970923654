import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { auth } from './auth.module'
import { user } from './user.module'
import { sidebar } from './sidebar.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    user 
  },
  plugins: [createPersistedState()]
})
