export const user = {
    namespaced: true,
    state: {
        selectedUser : null
    },
    actions: {
        selectUser({ commit }, user) {
            commit('userSelected', user)
        },

    },
    mutations: {
        userSelected(state, user) {
            state.selectedUser = { ...user }
        },

    }
}
